<!-- 页脚 -->
<template>
  <div class="container-fiuled footer">
    <div class="row">
      <div class="col-md-6">
          <div class="img"><img src="../assets/img/img-n01.png"  alt=""/></div>
          <p>国家重点研发计划固废资源化重点专项</p>
          <p class="bor">固废资源化技术多源数据库开发及多维绩效评价方法研究（2018YFC1903601）</p>
          <p>联系我们：cbzhou@rcees.ac.cn / 010-62849147</p>
      </div>
      <div class="col-md-6 r-part">
        <div class="tit">本系统开发单位</div>
        <div class="row">
            <div class="col-md-6"><img src="../assets/img/img-n11.png"  alt=""/>中国科学院生态环境研究中心</div>  
            <div class="col-md-6"><img src="../assets/img/img-n12.png"  alt=""/>中国环境科学研究院</div>
            <div class="col-md-6"><img src="../assets/img/img-n13.png"  alt=""/>中国科学院过程工程研究所</div>
            <div class="col-md-6"><img src="../assets/img/img-n14.png"  alt=""/>中国国际工程咨询有限公司</div>
        </div>
      </div>
      <!-- <div class="clause">
        <p>条款与条件</p>
        <p> 一 </p>
        <p>法律声明 京ICP备 号 京公网安备 号</p>
      </div>
      <div class="contact">
        <p>联系我们</p>
        <p></p>
        <p>第三方</p>
        <ul>
          <li><img src="../assets/img/wechat.png"  alt=""/></li>
          <li><img src="../assets/img/weibo.png"  alt=""/></li>
          <li><img src="../assets/img/email.png"  alt=""/></li>
        </ul>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "footer"
}
</script>

<style lang="less" scoped>
@import "../assets/less/footer.less";
</style>
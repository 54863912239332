<template>
  <div class="header-n-box">
    <div class="cont">
      <header class="header">
        <div class="l-part">
          <div class="logo">
            <img alt="" src="../assets/img/img-n01.png"/>
          </div>
          <div class="title">
            <p class="cn">固废资源化数据库与技术绩效评价系统</p>
            <p class="en">
              Solid Waste Database and Information System for Assessing
              Recycling Technology
            </p>
          </div>
        </div>
        <div class="r-part">
          <div @click="go('nav')" style="float: left;cursor: pointer;"><img alt="" src="../assets/img/nav.png" style="padding-right: 2px"/>系统导航</div>
          <div class="login-u" v-if="loginFlag" @click="loginVisible = true">
            <img alt="" src="../assets/img/img-n02.png"/><span>登录</span>
            <!-- <img alt="" src="../assets/img/img-n03.png"><span>注册</span> -->
          </div>
          <div v-if="!loginFlag" class="login-u">
            <p>
              欢迎您, <b>{{ loginName }}</b>
            </p>
            <b-link href="#" class="logout" @click="logout()"> 退出</b-link>
          </div>
          <div class="more">
            <router-link to="/about" style="color: white">关于固废资源化数据库</router-link>
            |
            <router-link to="/statistical" style="color: white">统计资料</router-link>
          </div>
        </div>
      </header>
    </div>
    <!--Modal-->
    <el-dialog
        title="登录"
        :visible.sync="loginVisible"
        width="30%"
        :before-close="handleLoginCancel"
    >
      <el-input
          placeholder="请输入用户名"
          v-model="loginForm.username"
          prefix-icon="el-icon-user"
      >
      </el-input>
      <el-input
          placeholder="请输入密码"
          v-model="loginForm.password"
          show-password="true"
          prefix-icon="el-icon-lock"
          style="margin-top: 10px"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="loginVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleLoginOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ui from "@/libs/ui";
import core from "@/libs/core";
import {getToken} from "@/libs/cookie";

export default {
  name: "header-n",
  data() {
    return {
      loginName: "",
      loginForm: {},
      loginFlag: false,
      loginVisible: false,
    };
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    // debugger
    const token = getToken();
    if (token === "") {
      this.loginFlag = true;
    } else {
      this.loginFlag = false;
      this.loginName = this.$store.getters.name;
    }
  },
  methods: {
    handleLoginCancel() {
      this.loginVisible = false;
    },
    handleLoginOk() {
      let self = this;
      this.$store.dispatch("user/login", this.loginForm).then(() => {
        this.$store.dispatch("user/getInfo").then(() => {
          self.loginVisible = false;
          this.$router.go(0);
        });
      });
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        ui.toast(this, ui.variant.info, core.msg.logoutSucc);
        this.$router.go(0);
      });
    },
    go(path){
      this.$router.push(path)
    }
  },
};
</script>
<style lang="less" scoped>
@import "../assets/less/head-n.less";
</style>
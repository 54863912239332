import axios from 'axios'
import {getToken, removeToken, removeName} from './cookie'
import {Notification, MessageBox, Message} from 'element-ui'
import Vue from '@/main'
import { baseApi, desc } from '@/config'

// console.log(desc)

let http = {};
// 请求响应超时时间
axios.defaults.timeout = 8000;
const permitUrls = [
    'loginJwt/*',
]

http = axios.create({
    baseURL: baseApi,
    timeout: 5000
})

http.interceptors.request.use(config => {
        // Vue.$data.loading = true;
        if (getToken()) {
            config.headers['x-auth-token'] = getToken();
        } else {
            // debugger
            const i = permitUrls.filter(url => url.search(config.url)).length;
            if (i) return config;
            Vue.$router.push("/");
        }
        return config
    },
    error => {
        console.log(error);
        Promise.reject(error);
    }
);

http.interceptors.request.use(config => {
    // debugger
    // console.log(Vue,"1")
    Vue.$Loading = true
    return config
})

http.interceptors.response.use(res => {
    // debugger
    // console.log(Vue,"2")
    Vue.$Loading = false
        const code = res.status;
        if (code === 203) {
            MessageBox.confirm(
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '系统提示',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                removeToken();
                removeName()
                Vue.$router.push('/')
                location.reload() // 为了重新实例化vue-router对象 避免bug
            });
        }
        // debugger
        const appCode = res.data.code
        if (appCode !== '200' && !appCode) {
            Notification.error({
                title: '错误信息',
                message: res.data.msg
            });
            // Vue.$data.loading = false;
            return Promise.reject('error')
        } else {
            // Vue.$data.loading = false;
            // debugger
            return Promise.resolve(res.data.data)
        }
    },
    error => {
        console.log('err' + error);
        Message({
            message: error.message,
            type: 'error',
            duration: 3 * 1000
        });
        // Vue.$data.loading = false;
        return Promise.reject(error)
    }
)

export default http;
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'
import Layout from '../layout/layout'
import Navigation from '../layout/Navigation'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Main')
        }]
    },
    {
        path: '/nav',
        name: 'navigation',
        component: Navigation,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/data',
        name: 'data',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/data/Data')
        }, {
            name: 'dataDetails',
            path: '/data/dataDetails/',
            component: () => import('../views/DataDetails')
        }, {
            name: 'wasteFeatures',
            path: '/data/wasteFeatures/',
            component: () => import('../views/data/WasteFeatures')
        },
        {
            name: 'newlyIncreased',
            path: '/data/newlyIncreased/',
            component: () => import('../views/data/NewlyIncreased')
        },
        {
            name: 'productTech',
            path: '/data/productTech/',
            component: () => import('../views/data/ProductTech')
        }, {
            name: 'cityData',
            path: '/data/cityData/',
            component: () => import('../views/data/CityData')
        }, {
            name: 'cityMine',
            path: '/data/cityMine/',
            component: () => import('../views/data/CityMine')
        }]
    },
    {
        path: '/NewClassification',
        name: 'NewClassification',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/NewClassification')
        }]
    }, {
        path: '/classification',
        name: 'classification',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Classification')
        }]
    }, {
        path: '/compare',
        name: 'compare',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/compare/Compare')
        }, {
            path: '/compare/wasteCompare',
            component: () => import('../views/compare/WasteCompare')
        }, {
            path: '/compare/techCompare',
            component: () => import('../views/compare/TechCompare')
        }]
    },
    {
        path: '/definitions',
        name: 'definitions',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Definitions')
        }]
    },
    {
        path: '/problem',
        name: 'problem',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Problem')
        }]
    },
    {
        path: '/evaluate',
        name: 'evaluate',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/evaluate/FillEvaluate')
        }, {
            name: 'fillEvaluate',
            path: '/evaluate/fillEvaluate',
            component: () => import('../views/evaluate/FillEvaluate')
        }, {
            name: 'evaluateData',
            path: '/evaluate/evaluateData',
            component: () => import('../views/evaluate/EvaluateData')
        }]
    },
    {
        path: '/main',
        name: 'main',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Main')
        }]
    },
    {
        path: '/about',
        name: 'about',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/About')
        }]
    },
    {
        path: '/statistical',
        name: 'statistical',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Statistical')
        }]
    },
    {
        path: '/share',
        name: 'share',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Share.vue')
        }]
    },
    {
        path: '/search/:keyword',
        name: 'search',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Search')
        }]
    },
    {
        path: '/notice/:type',
        name: 'notice',
        component: Layout,
        meta: {
            requiresAuth: false
        },
        children: [{
            path: '/',
            component: () => import('../views/Notice')
        }]
    },
    {
        path: '/noticeDetail/:id',
        name: 'noticeDetail',
        component: Layout,
        meta: {
            requiresAuth: false,
            keepAlive: false
        },
        children: [{
            path: '/',
            component: () => import('../views/NoticeDetail')
        }]
    }
]

if (window.localStorage.getItem('isLogin')) {
    store.commit('setIsLogin', window.localStorage.getItem('isLogin'))
}

const router = new Router({
    mode: 'hash',
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.meta.requiresAuth)) {
        if (store.getters.isLogin) {
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        console.log('not need login');
        next()
    }
})

export default router

import http from "@/libs/http";

export function login(data) {
    let uri = 'loginJwt?username=' + data.username + "&password=" + data.password;
    return http.post(uri);
}

export function getInfo() {
    return http.get('user/getUserInfo');
}

export function logout() {
    return http.get('logoutJwt');
}
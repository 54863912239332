import Vue from '../main'

let ui = {
    variant: {
        primary: "primary",
        secondary: "secondary",
        danger: "danger",
        warning: "warning",
        success: "success",
        info: "info"

    },
    toast: function (variant, msg) {
        Vue.$bvToast.toast(msg, {
            title: '提示',
            variant: variant,
            autoHideDelay: 5000,
            appendToast: true
        })
    },
    toastLogin: function () {
        this.toast(this.variant.info, "请先登录帐号");
    },
    toastTokenExpire: function () {
        this.toast(this.variant.info, "登录状态过期，请重新登录");
    }
}

export default ui
<template>
  <div class="bg nav-content">
    <div>
      <img :src="logoImg"/>
    </div>
    <div>
      <img :src="titleImg"/>
    </div>
    <div class="nav-btn">
      <img :src="navBtn1" @click="go('wasteFeature')"/>
      <img :src="navBtn2" @click="dialog(2)"/>
      <img :src="navBtn3" @click="go('tech')"/>
      <img :src="navBtn4" @click="go('eval')"/>
      <img :src="navBtn5" @click="dialog(5)"/>
    </div>

    <el-dialog
        title="工业固废评测系统" :modal="false"
        :visible.sync="view.visible2"
        width="80%"
        class="detailDialog"
    >
      <ul>
        <li>面向大宗/特色工业固废资源高质综合利用，对于物理-湿法工艺技术进行中试/扩试技术指标测试，推进技术产业化。</li>
        <li>
          平台符合典型煤基固废、冶炼废渣、钙基固废等3大类固废资源化技术测试需求，适用于物理处置、酸/碱单一处理、酸碱复合处理、酸-碱-物理联合处理等5-6类不同工艺路线，技术验证和指标测试时间≤7天。
        </li>
      </ul>

      <div class="imgs">
        <img :src="nav21"/>
        <img :src="nav22"/>
      </div>

      <div class="nav-btns">
        <ul>
          <li>
            <button @click="go('share')">我要共享数据</button>
          </li>
          <li>
            <button @click="go('workbench')">资源化利用技术测评数据分析系统</button>
          </li>
        </ul>
      </div>
    </el-dialog>

    <el-dialog
        title="固废资源化基地碳减排评估系统" :modal="false"
        :visible.sync="view.visible5"
        width="80%"
        class="detailDialog"
    >

      <div>
        <img width="100%" :src="navSoftware"/>
      </div>

      <div class="nav-btns">
        <ul>
          <li>
            <button @click="go('software')">程序下载</button>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import logoImg from '@/assets/img/logo.png'
import titleImg from '@/assets/img/nav_title.png'
import navBtn1 from '@/assets/img/nav_btn_1.png'
import navBtn2 from '@/assets/img/nav_btn_2.png'
import navBtn3 from '@/assets/img/nav_btn_3.png'
import navBtn4 from '@/assets/img/nav_btn_4.png'
import navBtn5 from '@/assets/img/nav_btn_5.png'
import config from '@/config/index'
import nav21 from '@/assets/img/nav_2_1.png'
import nav22 from '@/assets/img/nav_2_2.png'
import navSoftware from '@/assets/img/nav_software.png'

export default {
  name: "Navigation",
  computed: {
    key() {
      return this.$route.path
    }
  },
  data() {
    return {
      loading: this.$root.$Loading,
      view: {
        visible2: false, visible5: false
      },
      logoImg, titleImg, navBtn1, navBtn2, navBtn3, navBtn4, navBtn5,
      config,
      nav21, nav22, navSoftware
    }
  },
  methods: {
    go(type) {
      let url
      switch (type) {
        case "wasteFeature":
          url = `${this.config.baseUrl}/#/data/WasteFeatures`
          break
        case "tech":
          url = `http://180.76.158.139:8008/dist/#/technology`
          break
        case "eval":
          url = `${this.config.baseUrl}/#/evaluate`
          break
        case "share":
          url = `${this.config.baseUrl}/#/share`
          break
        case "workbench":
          url = `https://wh62hk.aliwork.com/APP_QEQCIRK44SMPJRTIT093/workbench`
          break
        case "software":
          url = `${this.config.baseResource}/废弃物综合利用减碳测算.zip`
          break
      }

      window.open(url)
    },
    dialog(type) {
      if (type == 2) {
        this.view.visible2 = true
      } else {
        this.view.visible5 = true
      }
    }
  },
  watch: {
    loading() {
      // debugger
      return this.$root.$Loading
    }
  }
}
</script>

<style scoped lang="less">
.bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('~@/assets/img/nav_bg.png');
  background-size: 100% 100%;
}

.nav-content {
  text-align: center;
  vertical-align: middle;
  padding-top: 5%;

  div {
    margin-top: 2%;
  }

  .nav-btn {
    margin-top: 5%;

    img {
      margin-left: 1%;
      cursor: pointer;
      -webkit-box-reflect: below 10px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.1)));
    }
  }

  .imgs {
    margin-top: 5%;
  }

  .nav-btns {
    margin-top: 8%;
    text-align: center;

    ul {
      list-style: none !important;

      li {
        margin-top: 1%;
      }
    }

    button {
      background-color: #186AAB;
      color: white;
      width: 400px;
      height: 30px;
      font-size: 16px;
    }
  }
}

.detailDialog {
  /deep/ .el-dialog {
    margin-top: 2% !important;
  }

  /deep/ .el-dialog__header {
    background-color: rgba(13, 107, 172, 0.2);
    text-align: left;

    span {
      font-size: 24px;
      color: rgba(13, 107, 172, 1);
    }
  }

  /deep/ .el-dialog__body {
    padding-top: 3%;
    text-align: left;

    ul {
      list-style: inside;
      font-size: 18px;
    }
  }
}
</style>
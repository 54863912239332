import Cookies from 'js-cookie'

const tokenKey = 'token'
const nameKey = 'loginName'

export function getToken() {
    return Cookies.get(tokenKey) || ''
}

export function setToken(token) {
    return Cookies.set(tokenKey, token)
}

export function removeToken() {
    return Cookies.remove(tokenKey)
}

export function getName() {
    return Cookies.get(nameKey) || ''
}

export function setName(name) {
    return Cookies.set(nameKey, name)
}

export function removeName() {
    return Cookies.remove(nameKey)
}
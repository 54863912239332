import {login, logout, getInfo} from '@/api/user'
import {getToken, setToken, removeToken, getName, setName, removeName} from '@/libs/cookie'

const state = {
    token: getToken(),
    name: getName(),
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {username, password} = userInfo
        return new Promise((resolve) => {
            login({username: username.trim(), password: password}).then(data => {
                // eslint-disable-next-line no-debugger
                // debugger
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                // ui.toast(this, ui.variant.info, core.msg.userInfoSucc)
                // ui.toast(this, ui.variant.info, "欢迎您, " + this.$store.getters.name)
                resolve()
            })
        })
    },

    // get user info
    getInfo({commit, state}) {
        return new Promise((resolve) => {
            getInfo(state.token).then(data => {
                // eslint-disable-next-line no-debugger
                // debugger
                commit('SET_NAME', data.loginName)
                setName(data.loginName)
                resolve(data.loginName)
            })
        })
    },

    // user logout
    logout({commit}) {
        return new Promise((resolve) => {
            logout().then(() => {
                // debugger
                // const {code} = res
                // if(code === 'true'){
                    commit('SET_TOKEN', '')
                    commit('SET_NAME', '')
                    removeToken()
                    removeName()
                    // resetRouter()
                // }

                resolve()
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
let core = {
    msg: {
        noLogin: '未登录',
        loginError: '登录失败',
        logoutError: '退出失败',
        httpError: '请求数据异常',
        userInfoSucc: '获取用户信息成功',
        logoutSucc: '账号退出成功'
    },
    formatDate(dateStr, fmt) {
        let date = new Date(dateStr);
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }

        return fmt;
    }
}

export default core
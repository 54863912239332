<template>
  <div class="container-fiuled" style="background-color: #FFFFFF;">
    <div class="container navbar">
      <div class="title">
          <p class="cn">固废资源化数据库与技术绩效评价系统</p>
          <p class="en">Solid Waste Database and Information System for Assessing Recycling Technology</p>
      </div>
      <ul>
        <li v-for="(item,index) in navList" :key="item.id" :class="{ active: index === current }" @click="go(index)">
          <router-link :to="item.url">
            <p>
              <img :src="item.img" :alt="item.name"  v-if="index !== current">
              <img :src="item.img1" :alt="item.name" v-if="index == current">
            </p>
            <span>{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      current: 0,
      navList: [
        {
          url: "/",
          name: "首页",
          id: 1,
          img: require('../assets/img/img-n16.png'),
          img1: require('../assets/img/img-n04.png'),
        }, {
          url: "/classification",
          name: "技术分类",
          id: 2,
          img: require('../assets/img/img-n05.png'),
          img1: require('../assets/img/img-n15.png'),
        }, {
          url: "/data/WasteFeatures",
          name: "数据查询",
          id: 3,
          img: require('../assets/img/img-n06.png'),
          img1: require('../assets/img/img-n17.png'),
        }, {
          url: "/compare/wasteCompare",
          name: "数据分析",
          id: 4,
          img: require('../assets/img/img-n07.png'),
          img1: require('../assets/img/img-n18.png'),
        },
        {
          url: "/evaluate",
          name: "技术评价",
          id: 5,
          img: require('../assets/img/img-n08.png'),
          img1: require('../assets/img/img-n21.png'),
        }, {
          url: "/share",
          name: "我要共享数据",
          id: 6,
          img: require('../assets/img/img-n09.png'),
          img1: require('../assets/img/img-n20.png'),
        }, {
          url: "/problem",
          name: "常见问题",
          id: 7,
          img: require('../assets/img/img-n10.png'),
           img1: require('../assets/img/img-n19.png'),
        }
      ]
    }
  },
  methods: {
    go(index) {
      this.current = index//激活样式
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/navbar-n.less";
</style>
<template>
  <div>
    <Header/>
    <Navbar/>
    <router-view v-loading="loading" :key="key"/>
    <Footer/>
  </div>
</template>

<script>
// import Header from "@/components/Header";
import Header from "@/components/Header-n"; //新版
// import Navbar from "@/components/Navbar";
import Navbar from "@/components/Navbar-n";//新版
import Footer from "@/components/Footer";

export default {
  name: "layout",
  components: {Footer, Navbar, Header},
  computed: {
    key() {
      return this.$route.path
    }
  },
  data() {
    // debugger
    return {
      loading: this.$root.$Loading
    }
  },
  watch: {
    loading() {
      // debugger
      return this.$root.$Loading
    }
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import Router from 'vue-router'
import echarts from 'echarts'
import vSelect from 'vue-select'
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from "@/router/router";
import store from "@/store/store";


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Router)
Vue.use(BaiduMap, {ak: '0887cdb4d3f37767086b2a975f91cf5d'})
Vue.use(ElementUI)

Vue.prototype.$Loading = false

Vue.prototype.$echarts = echarts
Vue.component('v-select', vSelect)

const vue = new Vue({
    render: h => h(App),
    router,
    store
});
vue.$mount('#app')

export default vue